import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const footer = () => (
  <React.Fragment>
    <footer className="footer">
      <Container fluid={true}>
        <Row>
          <Col md={6}>{new Date().getFullYear()} © Chris Dunne.</Col>
          <Col md={6}>
            <div className="text-sm-end d-none d-sm-block">
              CMS for
              <a
                href={process.env.REACT_APP_CHRIS_DUNNE_URL}
                className="ms-1 text-decoration-underline"
                target="_blank"
                rel="noreferrer"
              >
                {process.env.REACT_APP_CHRIS_DUNNE_URL?.replace('https://', '')}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  </React.Fragment>
)

export const Footer = footer
