import React, { useEffect, useState } from 'react'
import RichTextEditor, { StyleConfigList, ToolbarConfig } from 'react-rte'
import { Col, Input, Row } from 'reactstrap'
import './editor.css'

interface EditorProps {
  onChange: Function
  defaultValue: string
}

export const INLINE_STYLE_BUTTONS: StyleConfigList = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
]

export const BLOCK_TYPE_DROPDOWN: StyleConfigList = [
  { label: 'Normal', style: 'unstyled' },
  { label: 'Heading Medium', style: 'header-two' },
  { label: 'Heading Small', style: 'header-three' },
  { label: 'Code Block', style: 'code-block' },
]

export const BLOCK_TYPE_BUTTONS: StyleConfigList = [
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Blockquote', style: 'blockquote' },
]

const editorToolbarConfig: ToolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'IMAGE_BUTTON',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS',
  ],
  INLINE_STYLE_BUTTONS,
  BLOCK_TYPE_DROPDOWN,
  BLOCK_TYPE_BUTTONS,
  BLOCK_ALIGNMENT_BUTTONS: [],
}

export const Editor = (props: EditorProps) => {
  const [value, setValue] = useState(
    RichTextEditor.createValueFromString(props.defaultValue, 'html'),
  )

  useEffect(() => {
    if (props.onChange) {
      props.onChange(value.toString('html'))
    }
  }, [value, props])

  const onChange = (value: any) => {
    setValue(value)
  }

  const onChangeSource = (event: any) => {
    setValue((prevState) =>
      prevState.setContentFromString(event.target.value, 'html'),
    )
  }

  return (
    <>
      <RichTextEditor
        value={value}
        onChange={onChange}
        toolbarConfig={editorToolbarConfig}
      />
      <Row className="mt-4">
        <Col>
          <Input
            type="textarea"
            className="form-control"
            id="editor-source"
            name="editor-source"
            rows={25}
            placeholder="Editor Source"
            value={value.toString('html')}
            onChange={onChangeSource}
          />
        </Col>
      </Row>
    </>
  )
}
