import React from 'react'
import { Icon } from '@ailibs/feather-react-ts'
import { layoutTheme } from '../../constants/layout'

interface LightDarkState {
  layoutMode: string
  onChangeLayoutMode: any
}

const lightDark = ({ layoutMode, onChangeLayoutMode }: LightDarkState) => {
  const mode =
    layoutMode === layoutTheme.DARKMODE
      ? layoutTheme.LIGHTMODE
      : layoutTheme.DARKMODE
  return (
    <div className="dropdown d-none d-sm-inline-block">
      <button
        onClick={() => onChangeLayoutMode(mode)}
        type="button"
        className="btn header-item"
      >
        {layoutMode === layoutTheme.DARKMODE ? (
          <Icon name="sun" className="icon-lg layout-mode-light" />
        ) : (
          <Icon name="moon" className="icon-lg layout-mode-dark" />
        )}
      </button>
    </div>
  )
}

export const LightDark = lightDark
