import { Course } from '@chrisdunne-com/chrisdunne-types'
import React, { useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap'
import { Editor } from '../../components/rte/Editor'
import { createImageAltText } from '../helper'
import { createSlugFromTitle } from '../../utils/resources'
import { putData } from '../../utils/server'

export default function AddCourseModal(props: {
  setmodal_backdrop: React.Dispatch<React.SetStateAction<boolean>>
  put_course: Function
  course?: Course
}) {
  const [editorValue, setEditorValue] = useState(props.course?.preview ?? '')

  function handleCourseSubmit(event: any) {
    event.preventDefault()

    const data: Course = {
      slug: createSlugFromTitle(event.target.elements.title.value),
      mainImage: {
        src: event.target.elements.mainImageSrc.value,
        alt: createImageAltText(
          event.target.elements.title.value,
          'feature image',
        ),
      },
      author: {
        image: {
          src: 'https://media.chrisdunne.com/static/about.png',
          alt: 'author image',
        },
        link: {
          href: event.target.elements.authorLinkHref.value,
          target: '_blank',
        },
        name: event.target.elements.authorName.value,
      },
      title: event.target.elements.title.value,
      description: event.target.elements.description.value,
      preview: editorValue,
      lastUpdated: new Date(),
      publishDate: props.course?.publishDate ?? new Date(),
      status: 'published',
      lessons: [],
      tags: [],
    }

    putData('/course', data).then(
      () => {
        props.put_course(data)
        props.setmodal_backdrop(false)
      },
      (error) => {
        console.log(error)
      },
    )
  }

  function editor_updated(value: string) {
    setEditorValue(value)
  }

  return (
    <div className="m-4">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Add Course
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.setmodal_backdrop(false)
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <form className="form" onSubmit={handleCourseSubmit}>
          <Row className="mb-4">
            <Label htmlFor="mainImageSrc" className="col-sm-3 col-form-label">
              Main Image Url:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="mainImageSrc"
                name="mainImageSrc"
                required
                defaultValue={props.course?.mainImage.src}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="authorLinkHref" className="col-sm-3 col-form-label">
              Author Link Url:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="authorLinkHref"
                name="authorLinkHref"
                required
                defaultValue={
                  props.course?.author.link.href ??
                  'https://www.linkedin.com/in/chris-dunne/'
                }
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="authorName" className="col-sm-3 col-form-label">
              Author Name:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="authorName"
                name="authorName"
                defaultValue={props.course?.author.name ?? 'Chris Dunne'}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="title" className="col-sm-3 col-form-label">
              Title:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="title"
                name="title"
                required
                defaultValue={props.course?.title}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="description" className="col-sm-3 col-form-label">
              Description:
            </Label>
            <Col sm={9}>
              <Input
                type="textarea"
                className="form-control"
                id="description"
                name="description"
                maxLength={500}
                rows={5}
                required
                defaultValue={props.course?.description}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="preview" className="col-sm-3 col-form-label">
              Preview:
            </Label>
            <Col sm={9}>
              <Editor
                onChange={editor_updated}
                defaultValue={props.course?.preview ?? ''}
              />
            </Col>
          </Row>
          <div className="modal-footer">
            <input type="submit" value="Submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </div>
  )
}
