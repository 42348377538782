import { withAuthenticator } from '@aws-amplify/ui-react'
import React, { useEffect, useState } from 'react'
import { BlogPost } from '@chrisdunne-com/chrisdunne-types'
import { Modal } from 'reactstrap'
import { deleteData, getData } from '../../utils/server'
import { Footer } from '../../components/footer'
import { Header } from '../../components/header'
import { Sidebar } from '../../components/sidebar'
import { Table } from '../../components/table'
import AddBlogModal from './post-modal'
import { daysUntilReview } from '../../utils/resources'
import { SocialButton } from '../../components/social'

function BlogPosts() {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [modalBlog, setModalBlog] = useState<BlogPost>()
  const [blogs, setBlogs] = useState<any[]>([])

  useEffect(() => {
    if (!success && !error) {
      getData('/post').then(
        (result: any) => {
          setSuccess(true)
          setBlogs(result)
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  })

  function tog_backdrop(item?: BlogPost) {
    setModalBlog(undefined)
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()

    if (item) {
      setModalBlog(item)
    }
  }

  function put_blog_post(blog: BlogPost) {
    if (blogs.some((x) => x.id === blog.id)) {
      const item = blogs.findIndex((x) => x.id === blog.id)
      blogs[item] = blog
    } else {
      setBlogs([...blogs, blog])
    }
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  function delete_item(id: string) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this Blog Post?') === true) {
      deleteData('/post', id).then(
        (result: any) => {
          setBlogs(blogs.filter((blog) => blog.id !== id))
        },
        (error: any) => {
          setError(error)
        },
      )
    }
  }

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <h4 className="font-size-18">Posts</h4>
            <button
              type="button"
              className="btn btn-success float-end"
              onClick={() => {
                tog_backdrop()
              }}
              data-toggle="modal"
              style={{ marginRight: '50px' }}
            >
              Add New
            </button>
            <Modal
              size="lg"
              isOpen={modal_backdrop}
              toggle={() => {
                tog_backdrop()
              }}
              backdrop={'static'}
              id="staticBackdrop"
            >
              <AddBlogModal
                setmodal_backdrop={setmodal_backdrop}
                put_blog_post={put_blog_post}
                blogPost={modalBlog}
              />
            </Modal>
            <Table columns={['🔗', 'Social', 'Title', 'Author', 'Review']}>
              {blogs.map((item: BlogPost, count) => (
                <tr
                  key={count}
                  className={
                    daysUntilReview(new Date(item.lastUpdated)) < 10
                      ? 'bg-danger'
                      : ''
                  }
                >
                  <td>
                    <a
                      href={`${process.env.REACT_APP_CHRIS_DUNNE_URL}/post/${item.slug}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bx-link-external"></i>
                    </a>
                  </td>
                  <td>
                    <SocialButton
                      text={`Check out this blog post by ${item.author.name}, "${item.title}"! ${item.description}`}
                      url={`https://chrisdunne.com/post/${item.slug}`}
                      hashtags="chrisdunne"
                    />
                  </td>
                  <td>{item.title}</td>
                  <td>{item.author.name}</td>
                  <td>{daysUntilReview(new Date(item.lastUpdated))} days</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        tog_backdrop(item)
                      }}
                      data-toggle="modal"
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-light btn-sm"
                      onClick={() => {
                        delete_item(item.id)
                      }}
                      data-toggle="modal"
                    >
                      <i className="bx bx-x"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </Table>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export const Posts = withAuthenticator(BlogPosts, {
  hideSignUp: true,
  loginMechanisms: ['email'],
})
