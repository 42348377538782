import { withAuthenticator } from '@aws-amplify/ui-react'
import React from 'react'

function Medias() {
  return <></>
}

export const Media = withAuthenticator(Medias, {
  hideSignUp: true,
  loginMechanisms: ['email'],
})
