function addDays(date: Date, days: number): Date {
  date.setDate(date.getDate() + days)
  return date
}

export function daysUntilReview(lastUpdated: Date): number {
  const reviewDate = addDays(lastUpdated, 60)
  const timeInMilisec: number = reviewDate.getTime() - new Date().getTime()
  return Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24))
}

export function createSlugFromTitle(title: string): string {
  return title
    .toLowerCase()
    .trim()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replaceAll(' ', '-')
    .replaceAll('--', '-')
}
