import { Card, Deck } from '@chrisdunne-com/chrisdunne-types'
import React, { useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap'
import { putData } from '../../utils/server'
import { createSlugFromTitle } from '../../utils/resources'

export default function AddDeckModal(props: {
  setmodal_backdrop: React.Dispatch<React.SetStateAction<boolean>>
  put_deck: Function
  deck?: Deck
}) {
  const [cards, setcards] = useState<Card[]>(props.deck?.cards ?? [])

  function handleDeckSubmit(event: any) {
    event.preventDefault()

    console.log(event.target.elements)

    let complete = false
    let iterator = 0
    const deckCards: Card[] = []

    do {
      if (
        event.target.elements[`front${iterator}`] &&
        event.target.elements[`back${iterator}`]
      ) {
        deckCards.push({
          front: event.target.elements[`front${iterator}`].value,
          back: event.target.elements[`back${iterator}`].value,
        })
        iterator++
      } else {
        complete = true
      }
    } while (!complete)

    console.log(deckCards)

    const data: Deck = {
      slug: createSlugFromTitle(event.target.elements.title.value),
      title: event.target.elements.title.value,
      image: event.target.elements.image.value,
      cards: deckCards,
      status: 'published',
    }

    console.log(data)

    putData('/flashcard', data).then(
      () => {
        props.put_deck(data)
        props.setmodal_backdrop(false)
      },
      (error) => {
        console.log(error)
      },
    )
  }

  function handleAddCard(e: any) {
    console.log(cards)
    const cardsInDeck = [...cards]

    cardsInDeck.push({
      front: '',
      back: '',
    })

    setcards(cardsInDeck)
    console.log(cards)

    e.preventDefault()
  }

  return (
    <div className="m-4">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Add Flash Card Deck
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.setmodal_backdrop(false)
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <form className="form" onSubmit={handleDeckSubmit}>
          <Row className="mb-4">
            <Label htmlFor="title" className="col-sm-3 col-form-label">
              Title:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="title"
                name="title"
                required
                defaultValue={props.deck?.title}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label htmlFor="image" className="col-sm-3 col-form-label">
              Deck Image Url:
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                className="form-control"
                id="image"
                name="image"
                required
                defaultValue={props.deck?.image}
              />
            </Col>
          </Row>
          {cards.map((card: Card, i) => (
            <div key={i}>
              <Row className="mb-4">
                <Label
                  htmlFor={`front${i}`}
                  className="col-sm-3 col-form-label"
                >
                  Front:
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    className="form-control"
                    id={`front${i}`}
                    name={`front${i}`}
                    required
                    defaultValue={card.front}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Label htmlFor={`back${i}`} className="col-sm-3 col-form-label">
                  Back:
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    className="form-control"
                    id={`back${i}`}
                    name={`back${i}`}
                    required
                    defaultValue={card.back}
                  />
                </Col>
              </Row>
            </div>
          ))}
          <button className="add-btn" onClick={handleAddCard}>
            Add
          </button>
          <div className="modal-footer">
            <input type="submit" value="Submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </div>
  )
}
