import React from 'react'
import { SidebarContent } from '../sidebarContent'

const sidebar = (props: any) => (
  <React.Fragment>
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        <SidebarContent location={{ pathname: window.location.href }} />
      </div>
    </div>
  </React.Fragment>
)

export const Sidebar = sidebar
