import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@ailibs/feather-react-ts'
import { LightDark } from '../layoutMode'
import logoSvg from '../../assets/images/logo-sm.svg'
import { layoutTheme } from '../../constants/layout'

export const Header = (props: any) => {
  const [search, setsearch] = useState<boolean>(false)
  const [isClick, setClick] = useState<boolean>(true)
  const [, setPosition] = useState<string>()
  const [open, setOpen] = useState<boolean>(false)

  const toggleTopDrawer = () => {
    setPosition('right')
    setOpen(!open)
  }

  function tToggle() {
    const { body } = document
    setClick(!isClick)
    if (isClick === true) {
      body.classList.add('sidebar-enable')
      document.body.setAttribute('data-sidebar-size', 'sm')
    } else {
      body.classList.remove('sidebar-enable')
      document.body.setAttribute('data-sidebar-size', 'lg')
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{' '}
                  <span className="logo-txt">Chris Dunne</span>
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{' '}
                  <span className="logo-txt">Chris Dunne</span>
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle()
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <Icon name="search" className="icon-lg" />
              </button>
              <div
                className={
                  search
                    ? 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show'
                    : 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <LightDark
              layoutMode={layoutTheme.LIGHTMODE}
              onChangeLayoutMode={props.onChangeLayoutMode}
            />

            <div
              onClick={() => {
                toggleTopDrawer()
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle"
              >
                <Icon name="settings" className="icon-lg" />
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}
